<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md4>
                  <v-datePickerCustom
                    :presets="presets"
                    v-model="search.date"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-select
                    v-model="search.status"
                    :items="userStatusList"
                    :label="$t('common.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-autocomplete
                    v-model="search.agent"
                    :items="agentList"
                    :clearable="true"
                    :label="$t('entity.agent')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('voucherReport.statisticalCharts')"
          color="green"
          flat
          full-width
        >
          <div style="height: 100%; text-align: center;">
            <div style="display: flex; justify-content: flex-end;">
              <div
                class="mr-2"
                style="display: flex; align-items: center;">
                <div style="width: 30px; height: 30px; background-color: #5cb860;"/>
                <span class="ml-2">{{ $t('report.active') }}</span>
              </div>
              <div
                class="ml-2"
                style="display: flex; align-items: center;">
                <div style="width: 30px; height: 30px; background-color: #BBBBBB;"/>
                <span class="ml-2">{{ $t('report.inactive') }}</span>
              </div>
            </div>
            <chartist
              v-if="data.series.length > 0"
              :data="data"
              :options="quantityOptions"
              style="width: 350px; height: 350px; margin: auto;"
              type="Pie" />
            <span v-else>{{ $t('common.noDataAvailable') }}</span>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import VDatePickerCustomer from 'Components/VDatePickerCustom'
import stringUtils from 'utils/stringUtils'
import { mapActions, mapGetters } from 'vuex'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
// import { format, addDays, addMonths } from 'date-fns'
// import { ISO_FORMAT } from 'utils/constants'
// import dateUtils from 'utils/dateUtils'
export default {
  components: {
    ChartistTooltip,
    VDatePickerCustomer
  },
  data: () => ({
    search: {
      date: null,
      status: null,
      agent: null
    },
    inputProps: { solo: false, style: { width: '270px' } },
    menuProps: { offsetY: true, closeOnContentClick: false },
    presets: [
      {
        label: 'report.days',
        type: 'date'
      },
      {
        label: 'report.months',
        type: 'month'
      },
      {
        label: 'report.years',
        type: 'year'
      }
    ],
    userStatusList: stringUtils.UserStatusList,
    agentList: [],
    data: {
      labels: [],
      series: []
    },
    quantityOptions: {
      plugins: [ChartistTooltip({ appendToBody: true })]
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  created () {
    let filter = {
      params: {
        excludeEntityInactive: true
      }
    }
    this.GET_CHILDREN_ENTITY(filter).then(
      function (res) {
        let data = res.data.data
        this.agentList = []
        for (let i = 0, size = data.length; i < size; i++) {
          this.agentList.push({
            value: data[i].id,
            text: data[i].name
          })
        }
      }.bind(this)
    )
  },
  methods: {
    /**
     * Search
     */
    onSearch: function () {
      let type = ''
      if (this.search.date.type === 'date') {
        type = 'days'
      } else if (this.search.date.type === 'month') {
        type = 'months'
      } else {
        type = 'years'
      }
      let filter = {
        params: {
          date: this.search.date.start,
          type: type,
          status: this.search.status,
          agent: this.search.agent
        }
      }
      this.isLoading = true
      this.REPORT_STATISTICS_USER_OF_AGENT(filter).then(
        function (res) {
          if (this.search.status === 1) {
            this.data.labels = [this.$t('report.active')]
            this.data.series = [res.data[0]]
          } else if (this.search.status === 0) {
            this.data.labels = [this.$t('report.inactive')]
            this.data.series = [res.data[1]]
          } else {
            this.data.labels = [this.$t('report.active'), this.$t('report.inactive')]
            this.data.series = res.data
          }
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_CHILDREN_ENTITY',
      'REPORT_STATISTICS_USER_OF_AGENT'
    ])
  }
}
</script>

<style lang="scss">
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #a6dea8;
  color: #fff;
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #a6dea8;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}
[data-y-axis]::after {
    content: attr(data-y-axis);
    position: absolute;
    top: 50%;
    left: 0px;
    font-size: 11px;
    color: #777;
    text-align: center;
    transform: rotate(-90deg)translateY(50%);
}
.active {
  fill: #5cb860;
}
.inactive {
  fill: #BBBBBB;
}
</style>
